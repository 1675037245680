import { provideTransloco, TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';

import { TranslocoHttpLoader } from './transloco-loader';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';

export enum AvailableLangs {
  EN = 'en',
  FR = 'fr',
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-UK',
        fr: 'fr-FR',
      },
    }),
    provideTransloco({
      config: {
        availableLangs: [AvailableLangs.EN, AvailableLangs.FR],
        defaultLang: AvailableLangs.FR,
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslocoService],
      useFactory: preloadUser,
    },
  ],
})
export class TranslocoRootModule {}

export function preloadUser(transloco: TranslocoService) {
  return function () {
    navigator.language.includes(AvailableLangs.EN)
      ? transloco.setActiveLang(AvailableLangs.EN)
      : transloco.setActiveLang(AvailableLangs.FR);
  };
}
