import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FallbackReason } from '@shared/types/fallback-reason.enum';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'stiilt-redirection-handler',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './redirection-handler.component.html',
  styles: ``,
})
export class RedirectionHandlerComponent {
  protected readonly FallbackReason = FallbackReason;
  public reason?: FallbackReason;

  constructor(private readonly activatedRoute: ActivatedRoute) {
    this.reason = activatedRoute.snapshot.queryParamMap.get('reason') as FallbackReason;
  }

  redirect() {
    window.open('https://www.fpsnavigator.com', '_self');
  }
}
