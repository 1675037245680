import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastService } from '@shared/services/toast.service';
import { first, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { StiiltConfirmDialogService } from '@shared/services/stiilt-confirm-dialog.service';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(
    private readonly updates: SwUpdate,
    private readonly toastService: ToastService,
    private readonly appRef: ApplicationRef,
    private readonly confirmService: StiiltConfirmDialogService,
    private readonly translateService: TranslocoService,
  ) {
    console.log('UpdateService ready');

    appRef.isStable
      .pipe(
        first((stable) => stable),
        switchMap(() => interval(1000 * 60 * 10)), //10 minutes
      )
      .subscribe(async () => {
        await updates.checkForUpdate();
      });

    updates.versionUpdates.subscribe(async (evt) => {
      console.log('UpdateService: versionUpdates', evt);
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          confirmService.confirmWithoutReject(
            translateService.translate('errors.warning'),
            translateService.translate('errors.dashboard_update_available'),
            () => {
              window.caches.keys().then((cacheNames) =>
                Promise.all(
                  cacheNames.map((cacheName) => {
                    caches.delete(cacheName);
                  }),
                ),
              );
              window.location.reload();
            },
          );
          break;
        case 'NO_NEW_VERSION_DETECTED':
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }
}
