import { Component } from '@angular/core';
import { ToastType } from '@shared/types/toast-type.enum';
import { UpdateService } from '@core/services/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  public title = 'fps-navigator-frontent';
  public toastTypes = Object.values(ToastType) as ToastType[];
  constructor(private readonly update: UpdateService) {}
}
