import { Injectable } from '@angular/core';
import { HttpContextToken, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { finalize, forkJoin, from, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getId, getInstallations } from 'firebase/installations';
import { FirebaseApp } from '@angular/fire/compat';
import { StatusEventsService } from '@shared/services/status-events.service';
import { EnvService } from '@core/services/env.service';

export const DISABLE_NULLISH_CLEAN = new HttpContextToken<boolean>(() => false);
export const KEYS_TO_ESCAPE_FROM_CLEAN = new HttpContextToken<string[]>(() => []);

@Injectable()
export class HttpListenerService implements HttpInterceptor {
  constructor(
    private readonly app: FirebaseApp,
    private readonly auth: AngularFireAuth,
    private readonly statusEventService: StatusEventsService,
    private readonly env: EnvService,
  ) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.url.startsWith('/api/')) {
      return next.handle(req);
    }

    this.toggleLoader(req.headers, true);

    if (req.body && !req.context.get(DISABLE_NULLISH_CLEAN)) {
      const keysToExclude = req.context.get(KEYS_TO_ESCAPE_FROM_CLEAN) || [];
      req = req.clone({
        body: this.removeNullValues(req.body, keysToExclude),
      });
    }

    if (req.params && !req.context.get(DISABLE_NULLISH_CLEAN)) {
      req = req.clone({
        params: this.removeNullParams(req.params),
      });
    }

    return forkJoin([this.auth.idToken.pipe(take(1)), from(getId(getInstallations(this.app))).pipe(take(1))]).pipe(
      map(([idToken, installationId]) => {
        let headers = req.headers.set(
          'x-fps-navigator-app',
          `FpsNavigatorApp;${installationId};${this.env.version};Dashboard;${window.navigator.userAgent}`,
        );
        headers = headers.set('Authorization', `Bearer ${idToken}`);

        return req.clone({ headers });
      }),
      switchMap((req: HttpRequest<unknown>) => {
        return next.handle(req).pipe(
          map((event: HttpEvent<unknown>) => {
            return event;
          }),
          finalize(() => {
            this.toggleLoader(req.headers, false);
          }),
        );
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private removeNullValues(obj: any, keysToEscape: string[] = []): any {
    const isArray = Array.isArray(obj);
    for (const k of Object.keys(obj)) {
      if (obj[k] === null) {
        if (isArray) {
          obj.splice(parseInt(k, 10), 1);
        } else {
          if (!keysToEscape.includes(k)) delete obj[k];
        }
      } else if (typeof obj[k] === 'object') {
        this.removeNullValues(obj[k]);
      }
      if (isArray && obj.length === parseInt(k, 10)) {
        this.removeNullValues(obj);
      }
    }
    return obj;
  }

  private removeNullParams(params: HttpParams): HttpParams {
    params.keys().forEach((key) => {
      if (
        params.get(key) === null ||
        params.get(key) === '' ||
        params.get(key) === undefined ||
        params.get(key) === 'undefined' ||
        params.get(key) === 'null' // Remove null and undefined string params when issue is fixed in angular side. https://github.com/angular/angular/issues/46272
      ) {
        params = params.delete(key);
      }
    });
    return params;
  }

  private toggleLoader(headers: HttpHeaders, shouldActive: boolean): void {
    if (!headers.has('NOLOADER')) {
      this.statusEventService.setHttpStatus(shouldActive);
    }
  }
}
