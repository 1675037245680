// @ts-nocheck
import { EnvService } from '@core/services/env.service';
export const EnvServiceFactory = (): EnvService => {
  const env = new EnvService();
  const browserWindow = window || {};

  const browserWindowEnv = browserWindow['__env'] || {};

  for (const key in browserWindowEnv) {
    if (Object.prototype.hasOwnProperty.call(env, key)) {
      env[key] = browserWindowEnv[key];
    }
  }
  return env;
};

export const EnvServiceProvider = {
  provide: EnvService,
  useFactory: EnvServiceFactory,
  deps: [],
};
