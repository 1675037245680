import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { RedirectionHandlerComponent } from '@shared/components/redirection-handler/redirection-handler.component';
import { redirectionHandlerGuard } from '@shared/guards/redirection-handler.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/', 'auth', 'login']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['/', 'app']);

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'app',
        pathMatch: 'full',
      },
      {
        path: 'app',
        loadChildren: () => import('@core/layout/layout.module').then((m) => m.LayoutModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
      },
      {
        path: 'auth',
        loadChildren: async () => (await import('@pages/auth')).routes,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToItems },
      },
      {
        path: 'redirection-handler',
        component: RedirectionHandlerComponent,
        canActivate: [redirectionHandlerGuard],
      },
      {
        path: '**',
        redirectTo: 'app',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
