import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmationService, MessageService, SharedModule as PrimeNGSharedModule } from 'primeng/api';
import { DatePipe, registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/compat/storage';
import { EnvServiceFactory } from '@core/providers/env.service.provider';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';

import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

import fr from '@angular/common/locales/fr';
import en from '@angular/common/locales/en';

registerLocaleData(fr);
registerLocaleData(en);

const dynamicEnv = EnvServiceFactory();

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    CoreModule,
    AngularFireModule.initializeApp(dynamicEnv.firebaseCredentials),
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PrimeNGSharedModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js?version=latest', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    ConfirmationService,
    DatePipe,
    MessageService,
    {
      provide: BUCKET,
      useValue: dynamicEnv.firebaseCredentials.storageBucket,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
