// @ts-nocheck
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';

const errorsToExclude = /auth|-100/;

Sentry.init({
  dsn: 'https://7fc69f128ee57ec279081a93e67d09fc@o4507979817877504.ingest.de.sentry.io/4507979821023312',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https?:\/\/localhost:4200\/api\//,
    /^https:\/\/devapp\.fpsnavigator\.fr\/api\//,
    /^https:\/\/app\.fpsnavigator\.fr\/api\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: window['__env'].sentry_environment,
  release: window['__env'].version,
  beforeSend: (event, hint) => {
    if (
      hint.originalException &&
      (errorsToExclude.test(hint.originalException.code) || errorsToExclude.test(hint.originalException.message))
    ) {
      return null; // Ignore the event
    }
    return event; // Send the event to Sentry
  },
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
