import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';

export const redirectionHandlerGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Promise<boolean | UrlTree> | boolean => {
  if (!route.queryParamMap.get('reason')) {
    return inject(Router).navigate(['/']);
  }
  return true;
};
